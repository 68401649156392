import React from "react";
import Button from "@mui/material/Button";
import "./RelationshipTypeButtons.css";
import { imageUrl } from "../../constant";

export default function RelationshipTypeButtons({ relationshipType }) {
  // if (relationshipType.includes("casual"))
  //   console.log(relationshipType, "relationshipType 1");
  // else console.log(relationshipType, "relationshipType 2");

  return (
    <>
      <div className="relationship_type_buttons">
        <Button
          color="orange"
          variant="contained"
          className={`relationship_button ${
            relationshipType == "friendship," ? "blurred" : ""
          }`}
        >
          Relationship
        </Button>
        <Button
          color="orange"
          variant="contained"
          className={`friendship_button ${
            !relationshipType.includes("friendship") ? "blurred" : ""
          }`}
        >
          Friendship
        </Button>
      </div>
      <div className="its_a_match">
        <img
          src={
            `${imageUrl}wp-content/themes/listar-child/assets/images/its%20a%20match.png`
          }
        />
      </div>
    </>
  );
}
// {`relationship_button ${relationshipType === 'relationship' ? 'blurred' : 'relationship_button'}`}
