import axios from "axios";
import { nodeApiUrl, wpApiUrl } from "./constant";

const api = {
  matchingUsers: (id, offset) => {
    console.log("matchingUsers test");

    const config = {
      headers: {
        "Content-Type": "application/json;charset=utf-8;",
      },
    };
    return axios.get(`${nodeApiUrl}user/${id}?_offset=${offset}`, config);

    // console.log(myRes,'myRes test 2');
  },
  matching: (data) => {
    return axios.post(wpApiUrl + "send_interested_user_ids", data);
  },
  matchingList: (data) => {
    return axios.post(wpApiUrl + "matching_list", data);
  },
  chatInsert: (data) => {
    return axios.post(wpApiUrl + "chat_insert", data);
  },
  chatList: (data) => {
    return axios.post(wpApiUrl + "chat_list", data);
  },
  userImage: (data) => {
    console.log(data.entries().next(), "formData in api");
    return axios.post(wpApiUrl + "user_image", data);
  },

  saveProfile: (data) => {
    return axios.post(`${nodeApiUrl}user/save-profile`, data);
  },
};

export default api;
