import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import RedoIcon from "@mui/icons-material/Redo";
import "./SwipeButtons.css";

export default function SwipeButtons({ swipe, goBack, people }) {
  return (
    <div className="swipeButtons">
      <IconButton
        className="swipeButtons__left"
        fontSize="12px"
        onClick={() => swipe("left")}
      disabled={people?.length === 0}
      >
        <CloseIcon fontSize="large" sx={{ color: "#ff6600" }} />
      </IconButton>
      {/* <IconButton
        className="swipeButtons__left"
        fontSize="12px"
        onClick={() => goBack()}
      >
        <RedoIcon fontSize="large" sx={{ color: "#ff6600" }} />
      </IconButton> */}
      <IconButton
        className="swipeButtons__right"
        onClick={() => swipe("right")}
        disabled={people?.length === 0}

      >
        <DoneIcon fontSize="large" sx={{ color: "#ff6600" }} />
      </IconButton>
    </div>
  );
}
