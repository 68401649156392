import React, { useState, useEffect, useRef, useMemo } from "react";
import TinderCard from "react-tinder-card";
import "./Matching.css";
import SwipeButtons from "./components/SwipeButtons";
import RelationshipTypeButtons from "./components/RelationshipTypeButtons";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import api from "../api";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Alert, Spinner } from "react-bootstrap";

let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

theme = createTheme(theme, {
  palette: {
    orange: theme.palette.augmentColor({
      color: {
        main: "#ff6600",
      },
      name: "orange",
    }),
  },
  customAlert: {
    borderColor: "#ff6600ad",
    color: "#ff6600ad",
    backgroundColor: "white",
    fontWeight: "bold",
    border: "2px solid",
  },
});

export default function Matching({ userId }) {
  const [people, setPeople] = useState([]);
  const [images, setImages] = useState({});
  const [loadCard, setLoadCard] = useState(false);
  const [loader, setLoader] = useState(false);

  // const [currentIndex, setCurrentIndex] = useState(people?.length - 1);
  const [currentIndex, setCurrentIndex] = useState();
  const [lastDirection, setLastDirection] = useState("right");
  const [processedIndex, setProcessedIndex] = useState([]);
  const [offset, setOffset] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);  

  useEffect(() => {
    getAllProfiles();
  }, [offset]);

  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(people.length)
        .fill(0)
        .map((i) => React.createRef()),
    [people.length]
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const canGoBack = currentIndex < people.length - 1;

  const canSwipe = currentIndex >= 0;

  // inCrease the offset when i click swaped right to last user.
  const addTheOffsetHandler = () => {
    if (currentIndex === 0) {
      setOffset((prev) => prev + 1);
    }
  };

  const swiped = (direction, nameToDelete, index, character) => {
    const updatedCharater = { logged_in_user_id: userId, ...character };
    setLastDirection(direction);
    updateCurrentIndex(index - 1);
    if (direction == "right") {
      saveUserProfile(updatedCharater);
    }
  };

  const swipe = async (dir) => {
    console.log("swipe called");
    if (canSwipe && currentIndex < people.length) {
      await childRefs[currentIndex].current.swipe(dir);
    }
    addTheOffsetHandler();
  };

  const goBack = async () => {
    if (!canGoBack) return;
    const newIndex = currentIndex + 1;
    updateCurrentIndex(newIndex);
    await childRefs[newIndex].current.restoreCard();
  };

  const getAllProfiles = () => {
    setIsLoading(true);

    api
      .matchingUsers(userId, offset)
      .then((response) => {
        console.log("response test", response);

        if (response.data.hasOwnProperty("matches")) {
          let cdata = [];
          let insertedId = [];

          response.data.friendships.forEach(async (item) => {
            console.log(item, "item person");
            let obj = {
              id: item.wp_user_id,
              name: item.name,
              bio: item.bio,
              age: item.age,
              relationship: item.relationship,
            };

            if (insertedId.includes(obj.id) === false) {
              insertedId.push(obj.id);
              cdata.push(obj);
            }
          });

          response.data.matches.forEach(async (item) => {
            let obj = {
              id: item.wp_user_id,
              name: item.name,
              bio: item.bio,
              age: item.age,
              relationship: item.relationship,
            };
            cdata.push(obj);
          });

          setPeople(response.data.matches);
          updateCurrentIndex(response.data.matches.length - 1);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const outOfFrame = (name, idx) => {
    // console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current);
    setPeople((prevPeople) => prevPeople.filter((_, index) => index !== idx));
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  };

  const saveUserProfile = async (values) => {
    try {
      const data  = await api.saveProfile(values);
      
      if (data?.data?.data) {
        setRedirecting(true);
        setLoader(true)
        setTimeout(() => {
          window.location.href = "https://easytribe.com2go.co";
        }, 200);
        return true;
      }
      return data
    } catch (error) {
      console.log("error", error);
    }
  };

  if (redirecting) {
    return (
      <div className="center_div" style={{height:"100vh"}}>
        <div style={{ textAlign: 'center' }}>
          <Spinner animation="border" variant={"danger"} />
          <p>Redirecting...</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container style={{ height: "285px" }}>
          {isLoading ? (
            <div className="center_div">
              <Spinner animation="border" variant={"danger"} />
            </div>
          ) : (
            <>
              {people.length > 0 ? (
                <div className="tinderCards__cardContainer">
                  {people.map((character, index) => (
                    <TinderCard
                      ref={childRefs[index]}
                      className="swipe"
                      key={character.name}
                      onSwipe={(dir) =>
                        swiped(dir, character.name, index, character)
                      }
                      onCardLeftScreen={() => outOfFrame(character.name, index)}
                    >
                      <div
                        style={{
                          backgroundImage: "url(" + character.profile_img + ")",
                        }}
                        className="card"
                      >
                        <h3>{character.name}</h3>
                      </div>
                    </TinderCard>
                  ))}
                </div>
              ) : (
                <div className="center_div">
                  <Alert variant="warning" style={theme.customAlert}>
                    No User Found !!.
                  </Alert>
                </div>
              )}
            </>
          )
          }
        </Container>

        <Container fluid>
          <Row className="swip_row">
            <SwipeButtons swipe={swipe} goBack={goBack} people={people} />
          </Row>
        </Container>
        {people.length >= 0 ? (
          <>
            <RelationshipTypeButtons
              relationshipType={"Sachin"}
              variant="contained"
            />
            {people[currentIndex] && (
              <Container fluid>
                <Row className="justify-content-md-center">
                  <div className="profile_details">
                    <h2 className="profile_name">
                      {people[currentIndex]?.name}
                    </h2>
                    <h2 className="profile_name">
                      {people[currentIndex]?.age}
                    </h2>
                  </div>
                </Row>
                <Row className="justify-content-md-center">
                  <p className="profile_bio">{people[currentIndex]?.bio}</p>
                </Row>
              </Container>
            )}
          </>
        ) : (
          <div>No relationship information available.</div>
        )}
      </ThemeProvider>
    </>
  );
}
