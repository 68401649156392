import "./App.css";
import Navbar from "./Navbar";
import Matching from "./pages/Matching";
import Chats from "./pages/Chats";
import ChatScreen from "./pages/ChatScreen";
import { useState } from "react";
import "./assets/media.css";
import { Navigate, Route, Routes } from "react-router-dom";

function App() {
  // let id = document.getElementById('user_id').value;
  // let id = 1126;
  let id = 2916;

  const [tab, setTab] = useState("matching");
  const [userId, setUserId] = useState(id);
  const [match, setMatchs] = useState(id);

  const setActiveTab = (tab) => {
    setTab(tab);
  };

  const setMatch = (item) => {
    setMatchs(item);
  };

  return (
    <Routes>
      <Route path="/" element={<Matching userId={userId} />} />
      {/* <Route path="*" element={<Navigate  to={"/users"} />} /> */}
    </Routes>
    // <div className="App">

    //   <Navbar setActiveTab={setActiveTab} />
    //   {/* {tab === "matching" && <Test userId={userId} />} */}
    //   {tab === "matching" && <Matching userId={userId} />}

    //   {/* {tab === "chats" && (
    //     <Chats
    //       setActiveTab={setActiveTab}
    //       userId={userId}
    //       setMatch={setMatch}
    //     />
    //   )}
    //   {tab === "chatScreen" && <ChatScreen match={match} userId={userId} />} */}
    // </div>
  );
}

export default App;
